import * as React from "react";
import "./corona.scss";
import { connect } from "react-redux";
import { apiPrefix, baseTitle, TableCSVExporter } from "../../helper";
import { ToastContainer, toast } from "react-toastify";
import OutsideClick from 'detect-outside-click-react';
import { Helmet } from "react-helmet";
import axios from "axios";
import ReactLoading from "react-loading";
import Layout from "../Layout";
import { logoutUser } from "../login/actions/authActions";
import Pagination from "react-js-pagination";

interface Props {
  token: any;
  location: any;
  logoutUser: () => any;
}

interface User {
  sender_id: number | undefined;
  profile_pic: string | undefined;
  first_name: string;
  last_name: string;
  session?: number;
  joining_date?: string;
  joined?: string;
  phone?: string;
  test_name?: string;
}

const TITLE = `${baseTitle} People`;

const columns = [
  {
    label: 'Name',
    field: 'test_name',
    sort: 'asc',
    width: 50
  },
  {
    label: 'Self Test',
    field: 'test_name',
    sort: 'asc',
    width: 100
  },
  {
    label: 'Test Time',
    field: 'updated_at',
    sort: 'asc',
    width: 100
  },
  {
    label: 'Gender',
    field: 'gender',
    sort: 'asc',
    width: 25
  },
  {
    label: 'Age',
    field: 'age',
    sort: 'asc',
    width: 100
  },
  {
    label: 'Region',
    field: 'region',
    sort: 'asc',
    width: 50
  },
  {
    label: 'Fever',
    field: 'fever',
    sort: 'asc',
    width: 50
  },
  {
    label: 'Temperature',
    field: 'temperature',
    sort: 'asc',
    width: 50
  },
  {
    label: 'Breathe',
    field: 'breathe',
    sort: 'asc',
    width: 50
  },
  {
    label: 'Cough',
    field: 'cough',
    sort: 'asc',
    width: 50
  },
  {
    label: 'Headache',
    field: 'headache',
    sort: 'asc',
    width: 50
  },
  {
    label: 'Visit Affected Countries',
    field: 'visit_sick_peoples',
    sort: 'asc',
    width: 50
  },
  {
    label: 'Status',
    field: 'result',
    sort: 'asc',
    width: 50
  }
]

class CoronaTest extends React.Component<
  Props,
  {
    users: any;
    chatNow: boolean;
    atribute: string | null;
    operator: string | null;
    value: string | null;
    attributes: [];
    operators: [];
    user: any;
    messages: [];
    currentPage: any;
    data: any;
  }
  > {
  constructor(props: Props) {
    super(props);
    this.state = {
      users: null,
      chatNow: false,
      atribute: null,
      operator: null,
      value: "",
      attributes: [],
      operators: [],
      user: null,
      messages: [],
      currentPage: null,
      data: null
    };
    this.fetchUsers();
    this.getAttrAndOperators();
  }

  fetchUsers = async (page = null) => {
    try {
      let url = apiPrefix  + `coronaUsers/messenger${page ? page : ''}`;
      let headers = {
        Authorization: `Bearer ${this.props.token}`
      };
      let response: any = await axios({
        method: "get",
        url: url,
        headers: headers
      });
      console.log("User response", response.data);
      if (response.data) {
        let usersData = response.data.data.users.data
        this.setState({ users: usersData, 
          data: response.data.data.users,
        });
      }
    } catch (error) {
      console.log(error);
      // this.props.logoutUser();
    }
  };

  handlePagination = (page) => {
    this.fetchUsers(`?page=${page}`)
    // this.props.history.push(`?page=${page}`)
  }

  exportToCsv = () => {
    const dataTable = document.getElementById("LeadsDataTable");

    const exporter = new TableCSVExporter(dataTable);
    const csvOutput = exporter.convertToCSV();
    const csvBlob = new Blob([csvOutput], { type: "text/csv" });
    const blobUrl = URL.createObjectURL(csvBlob);
    const anchorElement = document.createElement("a");

    anchorElement.href = blobUrl;
    anchorElement.download = "table-export.csv";
    anchorElement.click();

    setTimeout(() => {
      URL.revokeObjectURL(blobUrl);
    }, 500);
  };

  filterLeads = async () => {
    let { atribute, operator, value } = this.state;

    if (atribute && operator && value) {
      try {
        let url = apiPrefix + "coronaData";
        let headers = {
          Authorization: `Bearer ${this.props.token}`
        };
        let response: any = await axios({
          method: "post",
          url: url,
          headers: headers,
          data: { attributes: atribute, operators: operator, dataString: value }
        });
        console.log("Filter User response", response.data);
        if (response.data) {
          this.setState({ data: response.data.data.searchData, 
            users: response.data.data.searchData.data });
        }
      } catch (error) {
        console.log("Leads filter error", error);
        // this.props.logoutUser();
      }
      return;
    }
    toast.error("Please select all field");
  };

  getAttrAndOperators = async () => {
    try {
      let url = apiPrefix + "attributes/corona";
      let headers = {
        Authorization: `Bearer ${this.props.token}`
      };
      let response: any = await axios({
        method: "get",
        url: url,
        headers: headers
      });
      let url1 = apiPrefix + "operators";
      let response1: any = await axios({
        method: "get",
        url: url1,
        headers: headers
      });
      console.log("Get attr opretor response", response.data);
      let attributes = response.data.data.attributes;
      let operators = response1.data.data.operators;
      if (attributes && operators) {
        this.setState({ attributes, operators });
      }
    } catch (error) {
      console.log(error);
      this.props.logoutUser();
    }
  };

  viewConversations = async user => {
    try {
      let url = `${apiPrefix}messages/${user.sender_id}`;
      let headers = {
        Authorization: `Bearer ${this.props.token}`
      };
      let response: any = await axios({
        method: "get",
        url: url,
        headers: headers
      });
      console.log("View converstaions: ", response.data);
      if (response.data.success) {
        this.setState({ chatNow: true, messages: response.data.data.messages });
      }
    } catch (error) {
      console.log(error);
      // this.props.logoutUser();
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="full-height">
          <Layout {...this.props}>
            <Helmet>
              <title>{TITLE}</title>
            </Helmet>
            <div className="add_segment">
              <div className="segment_header">
                <button type="button" className="clear_section">
                  Female User's
                </button>
                <button type="button" className="add_segment">
                  + Add Segment
                </button>
              </div>
              <div className="segment_body">
                <h4 className="select_title">Attribute</h4>
                <div className="attr">
                  <select
                    className="selector"
                    onChange={e => this.setState({ atribute: e.target.value })}
                  >
                    <option value="">Select</option>
                    {Object.values(this.state.attributes).map((item, i) => (
                      <option key={i} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                <h4 className="select_title">Operator</h4>
                <div className="operator">
                  <select
                    className="selector"
                    onChange={e => this.setState({ operator: e.target.value })}
                  >
                    <option value="">Select</option>
                    {Object.values(this.state.operators).map((item, i) => (
                      <option value={item} key={i}>{item}</option>
                    ))}
                  </select>
                </div>
                <h4 className="select_title">Value</h4>
                <div className="value">
                  <div className="selector">
                    <input
                      type="text"
                      className="selector"
                      value={this.state.value}
                      onChange={e => this.setState({ value: e.target.value })}
                    />
                  </div>
                </div>

                <div className="and">
                  <img src="assets/filter.png" />
                  <p
                    className="filter_name"
                    onClick={() => this.filterLeads()}
                    style={{ border: "none" }}
                  >
                    Filter
                  </p>
                </div>
                <div className="and ml-2">
                  <img src="assets/reset.png" />
                  <p
                    className="reset_name"
                    onClick={() => this.fetchUsers()}
                  >
                    Reset
                  </p>
                </div>
              </div>
              {/* <div className="segment_body">
                            <h4 className="select_title">Attribute</h4>
                            <div className="attr">
                                <select className="selector">
                                    <option value="volvo">Select</option>
                                    <option value="saab">First Name</option>
                                    <option value="opel">Last Name</option>
                                    <option value="audi">Sender Id</option>
                                    <option value="audi">gender</option>
                                    <option value="audi">sessions</option>
                                    <option value="audi">joingin date</option>
                                </select>
                            </div>
                            <h4 className="select_title">Operator</h4>
                            <div className="operator">
                                <select className="selector">
                                    <option value="Select">Select</option>
                                    <option value="volvo">is</option>
                                    <option value="saab">is Not</option>
                                    <option value="opel">Greater Than</option>
                                    <option value="audi">Less Than</option>
                                </select>
                            </div>
                            <h4 className="select_title">Value</h4>
                            <div className="value">
                                <div className="selector">
                                    <input type="text"  />
                                </div>
                            </div>

                            <div className="plus">
                                <i className="fa fa-plus" aria-hidden="true"></i>
                            </div>
                        </div> */}
            </div>
            <div className="new_card card">
              <div className="d-flex p_right  d-flex justify-content-end">
                {/* <div className="new_card_title">
                                <h4 className="select_title title_width">View</h4>
                                <div className="attr">
                                    <select className="selector">
                                        <option value="volvo">Select</option>
                                        <option value="saab">First Name</option>
                                        // <input type="checkbox" className="box" />
                                        <option value="opel">Last Name</option>
                                        <option value="audi">Sender Id</option>
                                        <option value="audi">gender</option>
                                        <option value="audi">sessions</option>
                                        <option value="audi">joingin date</option>
                                    </select>
                                </div>
                            </div> */}
                <div className="table_button">
                  {/* <button type="button" className="clear_section">
                    Delete
                  </button> */}
                  <img src="assets/share.png" />
                  <p className="add_segment " onClick={() => this.exportToCsv()}>
                    Export as CSV
                  </p>
                </div>
              </div>

              {/*<tr className="table_head">*/}
              {/*  <th scope="col">FB/Viber Name</th>*/}
              {/*  <th scope="col">User Name</th>*/}
              {/*  <th scope="col">Gender</th>*/}
              {/*  <th scope="col">Age</th>*/}
              {/*  <th scope="col">Region</th>*/}
              {/*  <th scope="col">Fever</th>*/}
              {/*  <th scope="col">Temperature</th>*/}
              {/*  <th scope="col">Difficult Breath</th>*/}
              {/*  <th scope="col">Dry Cough</th>*/}
              {/*  <th scope="col">Headache</th>*/}
              {/*  <th scope="col">Visit Affected Countries</th>*/}
              {/*  <th scope="col">Status</th>*/}
              {/*</tr>*/}

              <div className="card-body new_card_body" style={{overflowX: 'scroll'}}>
                {this.state.users && <table className="table new_table" id="LeadsDataTable">
                  <thead className="people-table-head">
                    <tr className="table_head">
                      {columns.map((item, i) => (<th key={i} scope="col">
                       {item.label}
                       </th>))}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.users &&
                      this.state.users.map((user: User, index: number) => (
                        <tr key={index}>
                         {columns.map((column, i) => (
                           <td key={i} style={{width: column.width}}>
                             {user[column.field]}
                           </td>
                         ))}
                        </tr>
                      ))}
                  </tbody>
                </table>
                }
                {this.state.users &&
                  <div className="d-flex justify-content-center">
                  <Pagination
                    activePage={this.state.data.current_page}
                    itemsCountPerPage={this.state.data.per_page}
                    totalItemsCount={this.state.data.total}
                    pageRangeDisplayed={10}
                    onChange={this.handlePagination}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </div>
                }
                {!this.state.users && (
                  <div className="alert alert-danger text-center" role="alert">
                    Sorry, No data found
                  </div>
                )}
                <div className="d-flex justify-content-center">
                  {!this.state.users && (
                    <ReactLoading
                      type={"bars"}
                      color={"#01a0fb"}
                      height={"10%"}
                      width={"10%"}
                    />
                  )}
                </div>
              </div>
            </div>
            {this.state.user && (
              <OutsideClick close={() => this.setState({ chatNow: false })}>
                <div
                  onBlur={() => console.log('Blured chat')}
                  className={`chat_now ${this.state.chatNow ? " " : "chat_hide"}`}
                >
                  <div className="inner_chat">
                    <div className="chat_now_header">
                      <div className="chat_now_image">
                        <img src={this.state.user.profile_pic} />
                      </div>
                      <div className="chat_now_image_name">
                        <p>
                          {" "}
                          {this.state.user.first_name} {this.state.user.last_name}
                        </p>
                      </div>
                      <div
                        className="chat_now_image_close"
                        onClick={() => this.setState({ chatNow: false })}
                      >
                        x
                    </div>
                    </div>
                    <div className="chat_now_body">
                      {this.state.messages.map((message: any, index) => (
                        <React.Fragment >
                          {message.message && (
                            <div className="chat_right" key={index}>
                              <div className="chat_right_txt">
                                <p>{message.message}</p>
                              </div>
                              <div className="chat_right_icon">
                                <img src={this.state.user.profile_pic} />
                              </div>
                            </div>
                          )}
                          {message.response && (
                            <div className="chat_left">
                              <div className="chat_left_icon">
                                <img src="assets/avatar.png" />
                              </div>
                              <div className="chat_left_txt">
                                <p>{message.response}</p>
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                    {/* <div className="chat_now_footer">
                  <input type="text" placeholder="type a message" />
                  <div className="sent">
                    <img src="assets/sent.png" />
                  </div>
                </div> */}
                  </div>
                </div>
              </OutsideClick>
            )}
          </Layout>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  token: state.auth.currentUserToken
});

export default connect(mapStateToProps, { logoutUser })(CoronaTest);
